import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Header } from '../components/header';

import * as stylesLayout from './index.module.css';
import * as stylesRelaxation from './relaxation.module.css';
import massageImage from '../images/relaxation_pano_1.jpg';
import imageChakras from '../images/relaxation-chakras.jpg';

const Relaxation = ({ data }) => {
  return (
    <div className={`${stylesLayout.main}`}>
      <div>
        <Helmet>
          <meta
            name="description"
            content="Relaxation dynamique ou statique. Elle va vous amener à vous recentrer, vous détendre et vous évader"
          />
          <title>{data.site.siteMetadata.title}</title>
        </Helmet>
        <Header></Header>
      </div>
      <div className={stylesLayout.mainContent}>
        <h1>La relaxation pour un moment de retour au « Moi »</h1>
        <img src={massageImage} alt="" className={stylesRelaxation.img} />
        <p className={stylesRelaxation.quote}>
          Venez-vous recentrer et évacuer le stress accumulé en vous, « lâcher prise »
        </p>

        <p className={stylesRelaxation.textIntro}>
          La séance dure environ 1 heure.
          <br />
          Elle se pratique allongé, assis ou debout, en groupe ou en individuel.
        </p>

        <p className={stylesRelaxation.textIntro}>
          Par de la <strong>respiration</strong>, je vous amènerai à vous <strong>détendre</strong> pour atteindre un
          relâché de votre corps et de l’esprit.
          <br />
          Par une prise de conscience de votre schéma corporel, vous vous approprierez votre corps, ainsi vous pourrez
          soulager, voir <strong>enlever vos tensions</strong>.
          <br />
          Je vous y aiderai en vous faisant faire des exercices dynamiques (étirements, vibrations, massage avec une
          balle, etc…).
          <br />
        </p>
        <p>
          Par de la <strong>visualisation</strong>, vous vous évaderez pour atteindre un{' '}
          <strong>état de bien-être</strong>.
        </p>
        <img src={imageChakras} alt="" className={`${stylesRelaxation.img} ${stylesRelaxation.imgSmall}`} />
        <p>
          Cette activité est destinée à ceux qui souhaitent s’accorder un moment de détente :
          <ul>
            <li>particuliers</li>
            <li>entreprises</li>
            <li>associations</li>
            <li>maisons de retraite</li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
export default Relaxation;
